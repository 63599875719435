<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2 md:pl-16">
        <img src="@/assets/Resources/Blog/Lo-ohm-min.png" />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2 md:pr-16">
        <img src="@/assets/Resources/Blog/Brush-and-Scoop-min.png" />
      </div>
      <div class="w-full">
        <TitleComponent title="Conductive Plastic Hand Tools" size="large" />
        <h2
          class="title-heading-center fusion-responsive-typography-calculated"
          style="margin: 0px; --fontSize:18; line-height: 1.5; --minFontSize:18;"
          data-fontsize="18"
          data-lineheight="27px"
        >
          <strong>DESIGNED FOR USE WITH ENERGETIC MATERIALS</strong>
        </h2>
      </div>
      <div class="w-full md:w-1/4 mb-6 md:pr-2">
        <img src="@/assets/Resources/Blog/Scoops-min.png" />
        <img src="@/assets/Resources/Blog/Spatulas-min.png" />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2 md:pr-2 mt-12">
        <Paragraph
          ><h2
            style="font-size:18px;line-height:1.5;margin-bottom:1.1em;"
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
          >
            <strong>FEATURES:</strong>
          </h2>
          <ul>
            <li>
              <h3
                data-fontsize="16"
                data-lineheight="24px"
                class="fusion-responsive-typography-calculated"
                style="font-size:16px;margin-top:1em;margin-bottom:1em;"
              >
                Non-metallic
              </h3>
            </li>
            <li>
              <h3
                data-fontsize="16"
                data-lineheight="24px"
                class="fusion-responsive-typography-calculated"
                style="font-size:16px;margin-top:1em;margin-bottom:1em;"
              >
                Carbon impregnated to retain conductivity during use
              </h3>
            </li>
            <li>
              <h3
                data-fontsize="16"
                data-lineheight="24px"
                class="fusion-responsive-typography-calculated"
                style="font-size:16px;margin-top:1em;margin-bottom:1em;"
              >
                Conductive to less than 10<sup>5</sup> ohms (ESD TR53-01-18)
              </h3>
            </li>
            <li>
              <h3
                data-fontsize="16"
                data-lineheight="24px"
                class="fusion-responsive-typography-calculated"
                style="font-size:16px;margin-top:1em;margin-bottom:1em;"
              >
                Chemical-resistant polypropylene construction
              </h3>
            </li>
            <li>
              <h3
                data-fontsize="16"
                data-lineheight="24px"
                class="fusion-responsive-typography-calculated"
                style="font-size:16px;margin-top:1em;margin-bottom:1em;"
              >
                Use minimizes friction, impact, and ESD stimulus
              </h3>
            </li>
          </ul>
        </Paragraph>
        <img src="@/assets/Resources/Blog/Scale-min.png" />
        <router-link
          class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mr-2"
          to="/shop/conductive"
        >
          Buy Now
        </router-link>
        <a
          class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2024%20Conductive%20Tools%20Price%20List%20-%20With%20Photos.pdf?alt=media&token=4893439e-4681-4dd5-8201-3996da63c9c9"
          target="_blank"
        >
          View Catalogue
        </a>
      </div>
      <div class="w-full md:w-1/4 mb-6 md:pl-2">
        <img src="@/assets/Resources/Blog/Brushes-min.png" />
        <img src="@/assets/Resources/Blog/Scrapers-min.png" />
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Lo-Ohm",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS offers a wide variety of conductive plastic tools designed to minimize ignition hazards during handling and clean-up."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
